// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects-list {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .projects-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .project-item {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .project-item h2 {
    margin: 0 0 10px 0;
  }
  
  .project-tags {
    margin-bottom: 10px;
  }
  
  .project-tag {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 5px;
  }
  
  .project-item p {
    margin: 0;
    color: #555;
  }
  `, "",{"version":3,"sources":["webpack://./src/style/Projects.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,SAAS;IACT,WAAW;EACb","sourcesContent":[".projects-list {\n    padding: 20px;\n    max-width: 800px;\n    margin: 0 auto;\n  }\n  \n  h1 {\n    text-align: center;\n    margin-bottom: 40px;\n  }\n  \n  .projects-list {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n  }\n  \n  .project-item {\n    background-color: #f9f9f9;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  }\n  \n  .project-item h2 {\n    margin: 0 0 10px 0;\n  }\n  \n  .project-tags {\n    margin-bottom: 10px;\n  }\n  \n  .project-tag {\n    display: inline-block;\n    background-color: #007bff;\n    color: white;\n    padding: 5px 10px;\n    margin-right: 5px;\n    border-radius: 5px;\n  }\n  \n  .project-item p {\n    margin: 0;\n    color: #555;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
