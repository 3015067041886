// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/images/cat.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardb {
    width: 100%;
    height: 100%; /* Adjust to fit the full slider */
    margin: 0;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center / cover; /* Properly cover the slider */
    border-radius: 0; /* Remove rounded corners */
    color: #fff;
    display: flex; /* Flexbox for centering content */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    text-align: center;
  }
  
  .card-content {
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
    padding: 40px;
    border-radius: 10px;
  }
  
  .card-title {
    font-size: 36px;
    margin: 0 0 20px;
    font-weight: bold;
  }
  
  .card-description {
    font-size: 18px;
    margin: 0;
    line-height: 1.8;
  }
  `, "",{"version":3,"sources":["webpack://./src/style/MyEfforts.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY,EAAE,kCAAkC;IAChD,SAAS;IACT,mFAA8E,EAAE,8BAA8B;IAC9G,gBAAgB,EAAE,2BAA2B;IAC7C,WAAW;IACX,aAAa,EAAE,kCAAkC;IACjD,mBAAmB,EAAE,sBAAsB;IAC3C,uBAAuB,EAAE,wBAAwB;IACjD,kBAAkB;EACpB;;EAEA;IACE,8BAA8B,EAAE,6BAA6B;IAC7D,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,SAAS;IACT,gBAAgB;EAClB","sourcesContent":[".cardb {\n    width: 100%;\n    height: 100%; /* Adjust to fit the full slider */\n    margin: 0;\n    background: url('../../public/images/cat.jpg') no-repeat center center / cover; /* Properly cover the slider */\n    border-radius: 0; /* Remove rounded corners */\n    color: #fff;\n    display: flex; /* Flexbox for centering content */\n    align-items: center; /* Center vertically */\n    justify-content: center; /* Center horizontally */\n    text-align: center;\n  }\n  \n  .card-content {\n    background: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */\n    padding: 40px;\n    border-radius: 10px;\n  }\n  \n  .card-title {\n    font-size: 36px;\n    margin: 0 0 20px;\n    font-weight: bold;\n  }\n  \n  .card-description {\n    font-size: 18px;\n    margin: 0;\n    line-height: 1.8;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
