// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General styles for sections */
.section {
  margin-bottom: 10px;
}

/* Updated section content styling */
.section-content {
  background-color: rgba(236, 240, 241, 0.8); /* Semi-transparent background */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out;
}

/* Text styling */
.section-content h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
  color: #333;
}

.section-content p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #555;
}

/* Flexbox layout for education information */
.education-info {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.university {
  font-weight: bold;
}

.dates {
  font-style: italic;
  color: #666;
}

.degree {
  text-align: center;
  margin-top: 10px;
  font-size: 1.1em;
}

/* Optional: Add hover effect for semi-transparent background */
.section-content:hover {
  background-color: rgba(236, 240, 241, 1); /* Fully opaque on hover */
}
`, "",{"version":3,"sources":["webpack://./src/style/Section.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;EACE,mBAAmB;AACrB;;AAEA,oCAAoC;AACpC;EACE,0CAA0C,EAAE,gCAAgC;EAC5E,aAAa;EACb,kBAAkB;EAClB,wCAAwC;EACxC,6CAA6C;AAC/C;;AAEA,iBAAiB;AACjB;EACE,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;AACb;;AAEA,6CAA6C;AAC7C;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA,+DAA+D;AAC/D;EACE,wCAAwC,EAAE,0BAA0B;AACtE","sourcesContent":["/* General styles for sections */\n.section {\n  margin-bottom: 10px;\n}\n\n/* Updated section content styling */\n.section-content {\n  background-color: rgba(236, 240, 241, 0.8); /* Semi-transparent background */\n  padding: 20px;\n  border-radius: 5px;\n  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);\n  transition: background-color 0.3s ease-in-out;\n}\n\n/* Text styling */\n.section-content h2 {\n  font-size: 1.8em;\n  margin-bottom: 10px;\n  color: #333;\n}\n\n.section-content p {\n  font-size: 1.2em;\n  line-height: 1.6;\n  color: #555;\n}\n\n/* Flexbox layout for education information */\n.education-info {\n  display: flex;\n  justify-content: space-between;\n  font-size: 1.2em;\n  margin-bottom: 10px;\n}\n\n.university {\n  font-weight: bold;\n}\n\n.dates {\n  font-style: italic;\n  color: #666;\n}\n\n.degree {\n  text-align: center;\n  margin-top: 10px;\n  font-size: 1.1em;\n}\n\n/* Optional: Add hover effect for semi-transparent background */\n.section-content:hover {\n  background-color: rgba(236, 240, 241, 1); /* Fully opaque on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
