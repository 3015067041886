// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Basic styling for the navbar */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px 20px;
  }
  
  .navbar-logo {
    font-size: 24px;
    color: white;
  }
  
  .navbar-links {
    list-style-type: none;
    display: flex;
    gap: 15px;
  }
  
  .navbar-links li {
    display: inline-block;
  }
  
  .navbar-links a {
    text-decoration: none;
    color: white;
    font-size: 18px;
  }
  
  .navbar-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 3px;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .navbar-links {
      display: none;
      flex-direction: column;
      gap: 10px;
      background-color: #333;
      position: absolute;
      top: 60px;
      right: 0;
      width: 100%;
      text-align: center;
    }
  
    .navbar-links.open {
      display: flex;
    }
  
    .navbar-toggle {
      display: flex;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/style/Navbar.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,YAAY;EACd;;EAEA;IACE,qBAAqB;IACrB,aAAa;IACb,SAAS;EACX;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,WAAW;IACX,uBAAuB;IACvB,WAAW;EACb;;EAEA,uBAAuB;EACvB;IACE;MACE,aAAa;MACb,sBAAsB;MACtB,SAAS;MACT,sBAAsB;MACtB,kBAAkB;MAClB,SAAS;MACT,QAAQ;MACR,WAAW;MACX,kBAAkB;IACpB;;IAEA;MACE,aAAa;IACf;;IAEA;MACE,aAAa;IACf;EACF","sourcesContent":["/* Basic styling for the navbar */\n.navbar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #333;\n    padding: 10px 20px;\n  }\n  \n  .navbar-logo {\n    font-size: 24px;\n    color: white;\n  }\n  \n  .navbar-links {\n    list-style-type: none;\n    display: flex;\n    gap: 15px;\n  }\n  \n  .navbar-links li {\n    display: inline-block;\n  }\n  \n  .navbar-links a {\n    text-decoration: none;\n    color: white;\n    font-size: 18px;\n  }\n  \n  .navbar-toggle {\n    display: none;\n    flex-direction: column;\n    cursor: pointer;\n  }\n  \n  .bar {\n    width: 25px;\n    height: 3px;\n    background-color: white;\n    margin: 3px;\n  }\n  \n  /* Responsive styling */\n  @media (max-width: 768px) {\n    .navbar-links {\n      display: none;\n      flex-direction: column;\n      gap: 10px;\n      background-color: #333;\n      position: absolute;\n      top: 60px;\n      right: 0;\n      width: 100%;\n      text-align: center;\n    }\n  \n    .navbar-links.open {\n      display: flex;\n    }\n  \n    .navbar-toggle {\n      display: flex;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
